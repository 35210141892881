.modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1050;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    outline: 0;
    background: rgba(0, 0, 0, 0.2);
}
.modal-content {
    width: 400px;
}
.modal button:active,
.modal button:focus {
    outline: none;
    box-shadow: none;
}