.vpndb-login .form-group {
    position: relative;
    margin-bottom: 0;
    padding-bottom: 22px;
}
.vpndb-login .form-control-user.form-control-error {
    border: 1px solid red;
}
.vpndb-login .form-control-user.form-control-error:focus {
    border: 1px solid red;
    box-shadow: 0 0 0 0.2rem rgba(255,0,0,.25);
}
.vpndb-login .form-control-errors-wrapper {
    position: absolute;
    top: 54px;
    left: 20px;
}
.vpndb-login .form-control-errors--wrapper p {
    margin: 0;
    line-height: 12px;
    font-size: 12px;
    color: red;
}