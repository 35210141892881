.error-boundary-wrapper {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4e73df;
    background-image: linear-gradient(180deg, #4e73df 10%, #224abe 100%);
    background-size: cover;
    color: #ffffff;
    text-align: center;
}
.error-boundary-wrapper h2 {
    font-size: 4rem;
}
.error-boundary-wrapper p {
    font-size: 2rem;
}